import React, { useState } from 'react';
import styled from 'styled-components';

import Button from './Button';
import PaypalModal from './PaypalModal';
import Video from './Video';
import dan from '../../assets/dan-burrell.jpg';
import certificate from '../images/certificate.png';

const Container = styled.section`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: repeat(2, auto);
  margin: 64px auto 64px auto;
  grid-gap: 50px;
  max-width: 1200px;
  & * {
    max-width: 80vw;
  }
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    width: 80vw;
    margin: 32px auto 64px auto;
  }
  @media screen and (max-width: 425px) {
    width: 90vw;
  }
`;

const TitleAndVideo = styled.article`
  grid-area: 1 / 1 / 2 / 2;
  br {
    display: none;
  }
  @media screen and (max-width: 1200px) {
    margin: 0 auto;
    br {
      display: block;
    }
  }
`;

const Subtitle = styled.h4`
  font-family: ${(props) => props.theme.fonts.excerpt};
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  margin-bottom: 16px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.title};
  font-size: 64px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  width: fit-content;
  letter-spacing: normal;
  text-align: left;
  display: inline;
  color: #666666;
  span {
    color: #fc3d2c;
  }
  @media screen and (max-width: 425px) {
    font-size: 32px;
  }
`;

const ShortDescription = styled.p`
  max-width: 736px;
  font-family: ${(props) => props.theme.fonts.excerpt};
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  margin-top: 16px;
`;

const LearningObjectives= styled.p`
  font-family: ${(props) => props.theme.fonts.excerpt};
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  grid-area: 2 / 1 / 3 / 2;
  span {
    font-weight: 500;
  }
  @media screen and (max-width: 1200px) {
    grid-area: 3 / 1 / 4 / 2;
    width: 80vw;
    margin: 0 auto;
  }
`;

const PriceAndCertificate = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  margin-top: 220px;
  hr {
    margin: 30px auto;
    max-width: 80vw;
  }
  @media screen and (max-width: 1200px) {
    grid-area: 2 / 1 / 3 / 2;
    width: 80vw;
    margin: 0 auto;
  }
`;

const PriceBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 80vw;
  margin: 0 auto;
`;

const Certificate = styled.img`
  border: 3px solid black;
  height: auto;
  width: 300px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.excerpt};
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

const TeacherInfo = styled.div`
  grid-area: 2 / 2 / 3 / 3;
  @media screen and (max-width: 1200px) {
    grid-area: 4 / 1 / 5 / 2;
  }
`;

const About = styled.h5`
  font-size: 24px;
  margin: 0 auto;
  font-family: ${(props) => props.theme.fonts.excerpt};
  text-align: center;
`;

const TeacherImage = styled.img`
  background-image: linear-gradient(red, blue);
  height: 255px;
  width: 255px;
  margin: 25px auto;
  border-radius: 100%;
  display: block;
  object-fit: cover;
`;

const TeacherName = styled.h5`
  font-family: ${(props) => props.theme.fonts.excerpt};
  margin: 0 auto;
  font-size: 20px;
  text-align: center;
`;

const TeacherDescription = styled.p`
  font-size: 18px;
  margin: 12px auto;
  font-family: ${(props) => props.theme.fonts.excerpt};
  text-align: center;
  font-weight: 300;
  @media screen and (max-width: 1200px) {
    width: 400px;
    max-width: 80vw;
  }
`;

const ClassInfo = () => {
  const [display, setDisplay] = useState(false);

  return (
    <>
    <Container>
      <TitleAndVideo>
        <Subtitle>Study with Dan Burrell</Subtitle>
        <Title>wheel of purpose <br /><span>the class</span></Title>
        <ShortDescription>Establish and identify your sense of purpose over the course of 8 weeks. Each session will be dedicated to deepening a different part of your life, with the goal of striking balance and creativity.</ShortDescription>
        <Video src="https://player.vimeo.com/video/427433191?title=0&byline=0&portrait=0" small/>
      </TitleAndVideo>
      <LearningObjectives>
        <span>What You'll Learn</span>
        <br />
        <br />
        In order to improve something about your life, the first step is to identify what is working and why it is working. If you love your job and feel fulfilled within your career, it’s because you’re good at establishing a goal, showing up, keeping up, and moving forward. The second step is to look at what could work better, and why it could work better.mMaybe you love your job but you wish you had more time to spend with your loved ones. Maybe you see your loved ones all the time, but the quality of time you have with them could be deeper. Maybe you're an excellent listener but you have a hard time listening to yourself. Throughout our 8 weeks together we will comprehensively assess where you are at, where you want to be, and establish a weekly plan on how to get there. 
        <br />
        <br />
        Throughout the class, you will be joined by others from all walks of life. This will create a supportive group atmosphere that will hold each individual accountable to the goals they set, and create a network of people with whom you can connect with and share ideas. 
        <br />
        <br />
        At the end of the 8 weeks, you will emerge with an integrated perspective of your life as a whole, and establish a clear path toward the next steps you can take to further deepen your relationship with yourself and others.
      </LearningObjectives>
      <PriceAndCertificate>
        <PriceBox>
          <Title>$295</Title>
          <Button handleClick={() => setDisplay(true)} className="long" text='ENROLL NOW'></Button>
        </PriceBox>
        <hr></hr>
        <Certificate src={certificate}/>
      </PriceAndCertificate>
      <TeacherInfo>
        <About>About Your Instructor</About>
        <TeacherImage src={dan}/>
        <TeacherName>Dan Burrell</TeacherName>
        <TeacherDescription>
          Dan is the founder of the Wheel of Purpose, a project created to support and assist those who seek a clearer understanding of their purpose. Drawing on his extensive and varied career in the army, as an actor, and as an entrepreneur who opened more than 150 Jersey Mike's franchise locations on the West Coast, Dan developed the Wheel of Purpose project to assist others in formulating the connection we all have in creating our own magic, leading to a happier and more balanced life.
        </TeacherDescription>
      </TeacherInfo>
      <div />
    </Container>
    <PaypalModal display={display} closeModal={() => setDisplay(false)}/>
    </>
  )
};

export default ClassInfo;
