import React from 'react';
import styled from 'styled-components';

import Button from './Button';
import ClassCard from './ClassCard';
import { classList } from '../content/classes-wheelofpurpose';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f6f6f9;
  padding-bottom: 60px;
`;

const Title = styled.h4`
  font-size: 38px;
  color: black;
  font-family: ${(props) => props.theme.fonts.title};
  text-align: center;
  margin: 50px 0;
  font-weight: 500;
`;

const List = styled.article`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 25px;
  margin: 0 50px 60px;
  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ClassList = () => (
  <Container>
    <Title>Course Details</Title>
    <List>
      { classList.map((cl, i) => (
        <ClassCard {...cl} index={i} />
      )) }
    </List>
    <Button className="long" text='ENROLL NOW'></Button>
  </Container>
);

export default ClassList;
