import React from 'react';
import styled from 'styled-components';

import classPhoto from '../images/class.png';

const Container = styled.section`
  display: flex;
  background: #f6f6f9;
  flex-direction: column;
`;

const SubContainer = styled.article`
  max-width: 1200px;
  margin: 50px auto;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: auto;
  grid-gap: 50px;
  align-items: center;
  @media screen and (max-width: 1200px) {
    width: 80vw;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-gap: 25px;
  }
  @media screen and (max-width: 425px) {
    width: 90vw;
  }
`;

const Wheel = styled.img`
  height: auto;
  width: 50vw;
  margin: 0 auto;
  grid-area: 1 / 2 / 2 / 3;
  @media screen and (max-width: 1200px) {
    grid-area: 4 / 1 / 5 / 2;
    width: 80vw;
  }
`;

const Title = styled.h4`
  font-family: ${(props) => props.theme.fonts.title};
  font-size: 36px;
  font-weight: 300;
  margin: 64px auto 0;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

const FirstText = styled.div`
  font-family: ${(props) => props.theme.fonts.excerpt};
  font-size: 18px;
  grid-area: 1 / 1 / 2 / 2;
  font-weight: 300;
  @media screen and (max-width: 1200px) {
    max-width: calc(100vw - 50px);
    margin: 0 auto;
  }
`;

const ClassDetails = () => (
  <Container>
    <Title>
      What is Your Purpose?
      <br />
      Set Aside Eight Weeks to Answer this Question</Title>
    <SubContainer>
      <Grid>
        <FirstText>
          You will work with a cohort of peers from all over the country who are discovering their purpose alongside you.
          <br />
          <br />
          You will be held accountable to each goal you establish on a weekly basis. 
          <br />
          <br />
          You will take regular time to reflect on your life, choices, and aspirations.
          <br />
          <br />
          You will be actively encouraged to deepen important personal and professional relationships.
          <br />
          <br />
          You will be actively encouraged to take close care of your physical and mental health.
          <br />
          <br />
          You will have both broad philosophical discussions as well as specific actionable ones, with the intention of improving your life on an individual and collective level.
          <br />
          <br />
          You will decide how to make an impact.
          <br />
          <br />
        </FirstText>
        <Wheel src={classPhoto} />
      </Grid>
    </SubContainer>
  </Container>
);

export default ClassDetails;
