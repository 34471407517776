import React, { useEffect } from 'react';

const Paypal = ({ theId = 'paypal-button-container' }) => {
  useEffect(() => {
    paypal.Buttons({
      style: {
        shape: 'rect',
        color: 'gold',
        layout: 'vertical',
        label: 'paypal',

      },
      createOrder: function(data, actions) {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: '295'
            }
          }]
        });
      },
      onApprove: function(data, actions) {
        return actions.order.capture().then(function(details) {
          alert('Transaction completed by ' + details.payer.name.given_name + '!');
        });
      }
    }).render(`#${theId}`);
  }, []);

  return (
    <div id={theId}></div>
  )
}

export default Paypal;
