import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  border-top: 25px solid red;
  box-sizing: border-box;
  box-shadow: 0px 10px 10px grey;
  width: 325px;
  @media screen and (max-width: 768px) {
    max-width: 90vw;
  }
`;

const ClassNumber = styled.h5`
  font-size: 22px;
  font-family: ${(props) => props.theme.fonts.excerpt};
  color: black;
  margin-top: 30px;
  text-align: center;
`;

const Title = styled.h6`
  font-size: 20px;
  font-family: ${(props) => props.theme.fonts.excerpt};
  color: black;
  margin-top: 30px;
  text-align: center;
`;

const Description = styled.p`
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.excerpt};
  color: black;
  margin: 25px 30px 50px 30px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
`;

const ClassCard = ({ description, index, title }) => (
  <Container>
    <ClassNumber>Class {index + 1}</ClassNumber>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Container>
);

export default ClassCard;
