import React from 'react';
import styled from 'styled-components';

import ClassDetails from '../components/ClassDetails';
import ClassInfo from '../components/ClassInfo';
import ClassList from '../components/ClassList';
import EnrollBlock from '../components/EnrollBlock';
import HeaderMargin from '../components/HeaderMargin';
import Layout from '../components/layout';
import ParagraphBlock from '../components/ParagraphBlock';
import WhatYoullGetBlock from '../components/WhatYoullGetBlock';
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO title="The Class" />
    <HeaderMargin />
    <ClassInfo />
    <ClassDetails />
    <ParagraphBlock />
    <ClassList />
    <WhatYoullGetBlock />
    <EnrollBlock />
  </Layout>
);
