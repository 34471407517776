import React from 'react';
import styled from 'styled-components';

import whatYoullGet from '../images/what-youll-get.png';

const Container = styled.section`
  display: flex;
  margin: 112px auto;
  justify-content: space-between;
  max-width: 1200px;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 60px auto;
  }
`;

const Wheel = styled.img`
  height: 300px;
  width: 300px;
  max-height: 80vw;
  max-width: 80vw;
  @media screen and (max-width: 1200px) {
    height: 70vw;
    width: 70vw;
  }
`;

const SubContainer = styled.article`
  margin: 0 auto;
  width: 45vw;
  max-width: 90vw;
  @media screen and (max-width: 1200px) {
    width: 80vw;
    margin: 0 auto;
  }
`;

const Title = styled.h4`
  font-family: Myriad Pro;
  font-size: 36px;
  font-weight: 300;
  @media screen and (max-width: 1200px) {
    margin-top: 64px;
  }
`;

const Paragraph = styled.p`
  margin-top: 32px;
  font-family: ${(props) => props.theme.fonts.excerpt};
  font-size: 18px;
  font-weight: 300;
`;

const ParagraphBlock = () => (
  <Container>
    <Wheel src={whatYoullGet} />
    <SubContainer>
      <Title>What You'll Get</Title>
      <Paragraph>
      At the end of the 8 weeks, you will have delved into different areas of your life and you will have a clear understanding of the next steps you can take to further deepen your relationship with yourself and others. You’ll have engaged with a supportive community of peers who are invested in your and their growth. You’ll have the resources and contacts necessary to take the next steps in finding your purpose and living an inspired life. 
      </Paragraph>
      </SubContainer>
    </Container>
);

export default ParagraphBlock;
