import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import Paypal from './Paypal';

const OuterModal = styled.div`
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: ${props => props.display ? 1 : 0};
  z-index: ${props => props.display ? 2 : -1};
`;

const InnerModal = styled.div`
  opacity: inherit;
  position: relative;
  width: fit-content;
  height: fit-content;
  max-height: 100vh;
  max-width: 90vw;
  margin: 0 auto;
  background: white;
  padding: 45px 45px 10px 45px;
  vertical-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Close = styled.p`
  position: absolute;
  top: 10px;
  font-size: 28px;
  right: 20px;
  font-weight: 300;
  font-family: ${(props) => props.theme.fonts.excerpt};
  &:hover {
    cursor: pointer;
  }
`;

export default ({ closeModal, display, image, index, option, theId = 'paypal-button-container' }) => (
  <OuterModal display={display}>
    <InnerModal>
      <Close onClick={() => closeModal()}>x</Close>
      <Container>
        <Paypal theId={theId}/>
      </Container>
    </InnerModal>
  </OuterModal>
);
