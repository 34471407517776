import React, { useState } from 'react';
import styled from 'styled-components';

import Button from './Button';
import PaypalModal from './PaypalModal';
import certificate from '../images/certificate-and-wheel.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.slate};
  width: 100vw;
  padding: 36px;
`;

const Title = styled.h2`
  font-size: 36px;
  color: white;
  margin: 0 ${(props) => props.shortboi ? 0 : 'auto'} 24px;
  font-family: ${(props) => props.theme.fonts.title};
  text-align: left;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80vw;
  margin: 0 auto;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;

const PriceBox = styled.div`
  width: 40vw;
  text-align: left;
  display: flex;
  padding: 64px;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  @media screen and (min-width: 769px and max-width: 1200px) {
    flex-direction: row;
    width: 80vw;
    * {
      margin: 0 20px;
    }
  }
`;

const Certificate = styled.img`
  width: 40vw;
  height: auto;
  @media screen and (max-width: 1200px) {
    width: 80vw;
  }
`;


const EnrollBlock = () => {
  const [display, setDisplay] = useState(false);
  
  return (
    <>
      <Container>
        <Title>Start Living With Purpose NOW</Title>
        <SubContainer>
          <Certificate src={certificate} />
          <PriceBox>
            <Title shortboi>$295</Title>
            <Button handleClick={() => setDisplay(true)} className="long" text='ENROLL NOW'></Button>
          </PriceBox>
        </SubContainer>
      </Container>
      <PaypalModal theId={"enroll-me-please"} display={display} closeModal={() => setDisplay(false)} />
    </>
  )
}

export default EnrollBlock;
