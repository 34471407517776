import React from 'react';

export const classList = [
  {
    title: 'Relationships',
    description: 'The quality of the relationship with the people you love and value most is essential to a balanced life. Are you present with your children, or are you too tired when you get home from work? Do you and your partner spend enough quality time together? These are the questions we will focus on as we delve deeper into relationships and how they impact us.'
  },
  {
    title: 'Self-Knowledge',
    description: 'For some, this might look like taking walks in solitude. Some of us also struggle with depression and anxiety, and even sometimes have a hard time visualizing a future. Throughout this class, we will identify the different ways in which we can attend to our mental and emotional health.'
  },
  {
    title: 'Professional Life',
    description: 'Your work is how you make your money and a massive part of how you spend your time. That’s why it is especially important that you are constantly moving in the direction of your professional purpose. Go for that promotion. Or start looking for other jobs and other opportunities if you are unhappy. Whatever you do, keep moving. In this class, we will figure out how.'
  },
  {
    title: 'Spirituality',
    description: 'Maybe you attended church or a mosque or a synagogue; maybe you believe in one god or many. Being spiritual, though, is only about you. It has nothing to do with anyone else. In this class we will talk about the different ways in which we access and manifest spirit.'
  },
  {
    title: 'Health',
    description: 'Are you breaking a sweat every day? Is your body moving enough? How’s your diet? Do you sleep well? Do you wake up feeling rested? When is the last time you got a physical? Literally every other part of the wheel is contingent on your body’s well-being. The better you feel, the better you feel. It’s as simple as that.'
  },
  {
    title: 'Play',
    description: 'What are the things that you do purely because they bring you joy? How much time in your day do you dedicate to finding a creative flow that makes you feel excited, like a little kid? In this class we will be talking about the importance of play and the ways in which play unlocks peace and purpose. '
  },

];
