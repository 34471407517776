import React from 'react';
import styled from 'styled-components';

const Container = styled.section`
`;

const SubContainer = styled.article`
  margin: 60px auto;
  width: 1050px;
  max-width: 90vw;
  @media screen and (max-width: 1200px) {
    max-width: 80vw;
  }
`;

const Title = styled.h4`
  font-family: Myriad Pro;
  font-size: 36px;
  font-weight: 300;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

const Paragraph = styled.p`
  margin-top: 32px;
  font-family: ${(props) => props.theme.fonts.excerpt};
  font-size: 18px;
  font-weight: 300;
`;

const ParagraphBlock = () => (
  <Container>
    <SubContainer>
      <Title>When You're Done</Title>
      <Paragraph>
        You will have spoken to a friend from high school you haven’t talked to in years. You will have spent a lot of time really thinking about what you want to do next, career-wise. You’ll have the first steps of a plan. You’ll be more aware of your body. Over the course of eight weeks you will have devoted eight hours to engaging and building self-understanding with like-minded people. This in itself is transformative, as are the personal insights that come throughout the process.        
      </Paragraph>
      </SubContainer>
    </Container>
);

export default ParagraphBlock;
